export function loadGA() {
  const s1 = document.createElement("script");
  s1.async = true;
  s1.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GTAG_ID}`;
  document.head.appendChild(s1);

  const s2 = document.createElement("script");
  s2.innerHTML =
    "window.dataLayer = window.dataLayer || [];\n" +
    "function gtag() {dataLayer.push(arguments)}\n" +
    "window.gtag = gtag;\n" +
    'gtag("js", new Date());' +
    `gtag("config", "${process.env.GTAG_ID}", {
        send_page_view: false,
        cookie_flags: "Secure"
      });`;
  document.head.appendChild(s2);
}
